html {
  background-color: #222244;
}

body {
  font-family: "Creepster", sans-serif;
  font-size: 16px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 48px;
  font-weight: normal;
  margin: 0;
}

@supports (-webkit-text-stroke: 2px #000000) {
  h1 {
    -webkit-text-stroke: 2px #000000;
    -webkit-text-fill-color: #ff7700;
  }
}

p {
  font-family: "Comic Neue", sans-serif;
}

.center {
  align-items: center;
  display: flex;
  height: 75vh;
  min-height: 472px;
  min-width: 800px;
  justify-content: center;
}

.board {
  align-items: center;
  background-color: #77ff77;
  background-image: url("./background-0.svg");
  background-repeat: no-repeat;
  background-position: -2px 0;
  background-size: 798px 470px;
  border: 2px solid black;
  height: 458px;
  margin: 0 auto;
  padding: 4px;
  position: relative;
  width: 786px;
}

.container {
  display: flex;
}

.header {
  margin: 0 0 0 48px;
  width: 424px;
}

.players {
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  display: flex;
  margin-bottom: 6px;
  z-index: 2;
}

.player {
  background-size: cover;
  height: 64px;
  width: 64px;
}

.pieces {
  background-color: #77ffff;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  box-shadow: inset 0 0 0 1px black;
  display: flex;
  flex-direction: column;
  margin-right: 6px;
  z-index: 1;
}

.piece {
  background-color: transparent;
  background-size: cover;
  height: 64px;
  width: 64px;
}

.squares {
  flex: 0 0 auto;
  display: flex;
  flex-wrap: wrap;
  width: calc(64px * 10);
  border: 1px solid currentColor;
}

.square {
  background-color: #ff7777;
  border: 1px solid currentColor;
  box-sizing: border-box;
  height: 64px;
  padding: 2px;
  width: 64px;
}

.square-label {
  display: block;
  font-size: 16px;
  line-height: 16px;
}

.square-5-9 {
  background-image: url("./arrow-0.svg");
  background-repeat: no-repeat;
}

.square-4-9 {
  background-image: url("./arrow-2.svg");
  background-repeat: no-repeat;
}

.square-3-9 {
  background-image: url("./arrow-0.svg");
  background-repeat: no-repeat;
}

.square-2-9 {
  background-image: url("./arrow-2.svg");
  background-repeat: no-repeat;
}

.square-1-9 {
  background-image: url("./arrow-0.svg");
  background-repeat: no-repeat;
}

.square-0-9 {
  background-image: url("./arrow-2.svg");
  background-repeat: no-repeat;
}

.square-4-0 {
  background-image: url("./arrow-0.svg");
  background-repeat: no-repeat;
}

.square-3-0 {
  background-image: url("./arrow-1.svg");
  background-repeat: no-repeat;
}

.square-2-0 {
  background-image: url("./arrow-0.svg");
  background-repeat: no-repeat;
}

.square-1-0 {
  background-image: url("./arrow-1.svg");
  background-repeat: no-repeat;
}

.controls {
  display: flex;
  flex-direction: column;
  margin-left: 6px;
}

.controls-label {
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  margin: 6px 0 22px;
  text-align: center;
}
