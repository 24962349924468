.container {
  left: 50%;
  padding: 0 96px 96px 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.instruction {
  background-color: #ffffff;
  border-color: #000000;
  border-style: solid;
  border-width: 2px;
  padding: 8px;
  max-width: 256px;
}

.instructor {
  background-image: url("./instructor-0.svg");
  bottom: -64px;
  height: 256px;
  position: absolute;
  right: -64px;
  width: 256px;
}

.button {
  appearance: none;
  background-color: #ffffff;
  border-color: inherit;
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  font-family: "Comic Neue", sans-serif;
  font-weight: 700;
  padding: 4px 8px;

  &:disabled {
    border-color: currentColor;
    color: currentColor;
  }
}

.animate:local {
  animation: animate 600ms ease-in-out infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg) scale(0.95);
  }
  50% {
    transform: rotate(5deg) scale(1);
  }
  100% {
    transform: rotate(0deg) scale(0.95);
  }
}
