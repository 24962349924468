.button {
  appearance: none;
  background-color: transparent;
  border-color: inherit;
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  font-family: inherit;
  height: 66px;
  padding: 0;
  width: 66px;

  &:disabled {
    border-color: currentColor;
    color: currentColor;
  }
}

@supports (-webkit-text-stroke: 2px black) {
  .button {
    -webkit-text-stroke: 2px black;
    -webkit-text-fill-color: #ff7700;
  }
}

.number {
  display: block;
  height: 62px;
  font-size: 48px;
  line-height: 60px;
  padding: 0;
  text-align: center;
  width: 62px;
}

.wow:local {
  animation: wow 300ms ease-in-out 1;
}

.wee:local {
  animation: wee 600ms ease-in-out 1;
}

.woo:local {
  animation: woo 600ms ease-in-out 1;
}

@keyframes wow {
  0% {
    transform: rotate(0deg) scale(0);
  }
  100% {
    transform: rotate(-720deg) scale(1);
  }
}

@keyframes wee {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(360deg) scale(0);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}

@keyframes woo {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(-360deg) scale(0);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}
